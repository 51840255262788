import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [{
		path: "/",
		name: "index",
		component: () => import("../views/Index.vue"),
	},
	{
		path: "/login",
		name: "about",
		component: () => import("../views/auth/Login.vue"),
	},
	{
		path: "/reset",
		name: "about",
		component: () => import("../views/auth/Reset.vue"),
	},
	{
		path: "/register",
		name: "about",
		component: () => import("../views/auth/Register.vue"),
	},
	{
		path: "/forgot",
		name: "about",
		component: () => import("../views/auth/Forgot.vue"),
	},
	{
		path: "/user-add/info",
		name: "about",
		component: () => import("../views/user-add/Info.vue"),
	},
	{
		path: "/user-add/account",
		name: "about",
		component: () => import("../views/user-add/Account.vue"),
	},
	{
		path: "/user-add/address",
		name: "about",
		component: () => import("../views/user-add/Address.vue"),
	},
	{
		path: "/business-add/business-info",
		name: "about",
		component: () => import("../views/business-add/BusinessInfo.vue"),
	},
	{
		path: "/business-add/user-info",
		name: "about",
		component: () => import("../views/business-add/UserInfo.vue"),
	},
	{
		path: "/business-add/account",
		name: "about",
		component: () => import("../views/business-add/Account.vue"),
	},
	{
		path: "/business-add/address",
		name: "about",
		component: () => import("../views/business-add/Address.vue"),
	},
	// {
	// 	path: "/back/setting",
	// 	name: "setting",
	// 	component: () => import("../views/back/Setting.vue"),
	// },
	// {
	// 	path: "/back/message",
	// 	name: "message",
	// 	component: () => import("../views/back/Message.vue"),
	// },
	//   {
	//     path: "/back/home",
	//     name: "home",
	//     component: () => import("../views/back/BackHome.vue"),
	//   },

	{
		path: "/back",
		name: "back",
		component: () => import("../views/layout/Index.vue"),
		children: [{
				path: "home",
				name: "back-home",
				component: () => import("../views/back/BackHome.vue"),
			},
			{
				path: "message",
				name: "back-message",
				component: () => import("../views/back/Message.vue"),
			},
			{
				path: "setting",
				name: "back-setting",
				component: () => import("../views/back/Setting.vue"),
			},
			{
				path: "account/receive",
				name: "account-receive",
				component: () => import("../views/back/account/Receive.vue"),
			},
			{
				path: "account/receive/submit",
				name: "receive-submit",
				component: () => import("../views/back/account/ReceiveSubmit.vue"),
			},
			{
				path: "account/cash",
				name: "account-cash",
				component: () => import("../views/back/account/Cash.vue"),
			},
			{
				path: "account/cash-form",
				name: "cash-form",
				component: () => import("../views/back/account/CashForm.vue"),
			},
			{
				path: "account/cash-form/local",
				name: "cash-form-local",
				component: () => import("../views/back/account/CashLocal.vue"),
			},
			{
				path: "account/cash-form/other",
				name: "cash-form-other",
				component: () => import("../views/back/account/CashOther.vue"),
			},
			{
				path: "account/cash/exchange",
				name: "cash-exchange",
				component: () => import("../views/back/account/Exchange.vue"),
			},
			{
				path: "order/receive-order",
				name: "receive-order",
				component: () => import("../views/back/order/ReceiveOrder.vue"),
			},
			{
				path: "order/cash-order",
				name: "cash-order",
				component: () => import("../views/back/order/CashOrder.vue"),
			},
			{
				path: "order/exchange-order",
				name: "exchange-order",
				component: () => import("../views/back/order/ExchangeOrder.vue"),
			},
		],
	},
];

const router = new VueRouter({
	mode: "hash",
	base: process.env.BASE_URL,
	routes,
});

export default router;